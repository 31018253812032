import http from "../../http-common";



export const getAllDestination = () => {
  return http.get("/destination");
};

export const getEmployees = () => {
  return http.get("/employee");
};

export const getPaymentPlans = () => {
  return http.get("/payment-plans");
};

export const getJobsByDestinationId = (destinationId: number, availabeForMuslims?: boolean) => {

  let jobUrl = 'job'
  if (destinationId) {
    jobUrl = jobUrl+`?destination_id=${destinationId}`;
  }

  if (availabeForMuslims) {
    jobUrl = jobUrl+`&available_for_muslims=${availabeForMuslims}`;
  }
  console.log('jobbb', jobUrl);
  return http.get(jobUrl);
};

export const submitClientApplication = (payload:any) => {

  console.log('payloadForm', payload);
  return http.post("/submit-application", payload);
};