import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from 'react-hook-form';

import {
  ChakraProvider,
  Box,
  Input,
  theme,
  Heading,
  Button,
  Select,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
// import { Lorem } from "react-lorem-component";

import { countryList, IjobData, IdestinationData, religionList, IemployeeData, IpaymentPlanData, workPermitPriorityList } from "../../Utils/Services";
import { getAllDestination, getEmployees, getJobsByDestinationId, getPaymentPlans, submitClientApplication } from "./ApplicationService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { format } from "path";
import { format } from 'date-fns'

// import Moment from 'react-moment';




export const ApplicationForm = () => {
  const [appliedCountry, setCountry] = useState("");
  const [religion, setReligion] = useState("");
  const [visaType, setVisaType] = useState("");
  const [residenceCountry, setresidenceCountry] = useState("");
  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState(false);
  // const [selectedExperienceCategory, setSelectedExperienceCategory] = useState({
  //   id: '',
  //   name: '',
  // });
  // const [experienceList, setExperienceList] = useState<IjobCategoryData[]>([]);
  useEffect(() => {
  }, []);
  const { register, handleSubmit, getValues, reset } = useForm();
  const [passportIssueDate, setPassportIssueDate] = useState(undefined);
  const [passportExpiry, setPassportExpiry] = useState(undefined);
  const [idVisaValidity, setIdVisaValidity] = useState(undefined);
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [filters, setFilters] = useState(undefined);
  // const [searchKeyword, setSearchKeyword] = useState("");
  const [civilStatus, setCivilStatus] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [destinationList, setDestinationList] = useState<IdestinationData[]>([]);
  const [employeeList, setEmployeeList] = useState<IemployeeData[]>([]);
  const [paymentPlanList, setPaymentPlanList] = useState<IpaymentPlanData[]>([]);
  const [jobList, setJobList] = useState<IjobData[]>([]);


  // const onSubmit = data => console.log(data);
  // const searchKeywordValue = (event: any) => {
  //   const { value } = event.target;
  //   console.log('search12', event);
  //   console.log('valuevalue', value);
  //   setSearchKeyword(value);

  // }
  useEffect(() => {
    getDestionList();
    getEmployeeList();
    getPaymentPlanList();
  }, []);
  const _handleSubmit = (values: any) => {


    values.passport_issue_date = passportIssueDate;
    values.passport_expiry = passportExpiry;
    values.id_visa_validity = idVisaValidity;
    values.date_of_birth = dateOfBirth;
    console.log('formSubmission', values);
    // return;
    let formData: any = new FormData();
    console.log(formData);
    // formData = values;

    console.log('datesss');
    console.log(values);
    console.log(formData);
    // if (selectedJobSectorList.length === 0) {
    //   toast.error("Error: Please add at atleast one experience")
    //   return;
    // }
    // return;

    Object.keys(values).forEach((key, val) => {
      if (
        (key === 'applicant_cv'
          || key === 'contract_scanned_image'
          || key === 'payment_receipt_image'
          || key === 'visa_copy'
          || key === 'residence_id_upload'
          || key === 'passport_image'
          || key === 'work_permit_scanned_copy'
          || key === 'job_offer_scanned_copy'
          || key === 'police_clearance_certificate'
          || key === 'school_certificate'
        )
      ) {


        formData.append(key, (values[key] && values[key].length > 0) ? values[key][0] : null);

        return
      }

      if (key === 'date_of_birth'
        || key === 'id_visa_validity'
        || key === 'passport_expiry'
        || key === 'passport_issue_date') {
        if (values[key] && values[key] !== undefined && values[key] !== 'undefined') {

          console.log('parseValue', format(values[key], "dd-MM-yyyy"));
          formData.append(key, format(values[key], "dd-MM-yyyy"));
        }

        return;
      }
      formData.append(key, values[key]);



    });

    console.log('finalFormData', formData);
    setIsLoadingSubmitButton(true);
    submitClientApplication(formData)
      .then((response: any) => {
        setIsLoadingSubmitButton(false);
        console.log('formSubmission0', response);
        if (response.data && response.data.error && response.data.error === 'application_already_exists') {

          toast.error("Error: Application Already Exists, use different phone number, email and passport number for each application")
          return;
        }
        response.data = response.data.data;
        if (response.data && response.data.id) {

          toast.success("Success: Application Submitted");
          reset();
          // window.location.href = 'https://https://wisgroups.com/thank-you-for-application-registration';
        } else {
          toast.error("Error: Please try again later");
          console.log(response);

        }
      })
      .catch((e: Error) => {
        setIsLoadingSubmitButton(false);
        console.log('errorrr');
        console.log(e);
        toast.error("Error: Please try again later");
      });
  };

  const getDestionList = () => {
    getAllDestination()
      .then((response: any) => {
        setDestinationList(response.data);
      })
      .catch((e: Error) => {
        console.log('errorrr');
        console.log(e);
      });
  };

  const getEmployeeList = () => {
    getEmployees()
      .then((response: any) => {
        setEmployeeList(response.data);
        return;
      })
      .catch((e: Error) => {
        console.log('errorrr');
        console.log(e);
      });
  };

  const getPaymentPlanList = () => {
    getPaymentPlans()
      .then((response: any) => {
        setPaymentPlanList(response.data);
        return;
      })
      .catch((e: Error) => {
        console.log('errorrr');
        console.log(e);
      });
  };

  const getJobs = (destinationId: any, availableForMuslims: boolean = false) => {

    if (religion === 'MUSLIM') {
      availableForMuslims = true;
    }
    getJobsByDestinationId(destinationId, availableForMuslims)
      .then((response: any) => {
        setJobList((response?.data && response?.data.length > 0) ? response.data : []);
      })
      .catch((e: Error) => {
        console.log('errorrr');
        console.log(e);
      });
  };

  return (
    <div>
      <ChakraProvider theme={theme}>
        <Box fontSize="xl">
          <Heading className='applicationTitle' as="h1" size="xl">
            Wizard Application Form
          </Heading>
          <Heading as="h2" size="md" textAlign="left" mt={10}>
            Personal Information
          </Heading>
          <form onSubmit={handleSubmit(_handleSubmit)}>
            <div className="row">
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='name'>First Name</FormLabel>
                <Input id='name' type='text' {...register('name')} />
              </FormControl>
              <FormControl w={['100%', '33%']}>
                <FormLabel htmlFor='middle_name'>Middle Name</FormLabel>
                <Input id='middle_name' type='text' {...register('middle_name')} />
              </FormControl>
              <FormControl w={['100%', '34%']} isRequired>
                <FormLabel htmlFor='sur_name'>Surname</FormLabel>
                <Input id='sur_name' type='text' {...register('sur_name')} />
              </FormControl>
            </div>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input id='email' type='text'  {...register('email')} />
              </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='phone_number'>Phone Number</FormLabel>
                <Input id='phone_number' type='text' {...register('phone_number')} />
              </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='date_of_birth'>Date of Birth</FormLabel>
                {/* <Input id='date_of_birth' type='text' {...register('date_of_birth')}/> */}
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='passport_issue_date'
                  className="form-control"
                  selected={dateOfBirth}
                  // {...register('date_of_birth')}
                  onChange={(date: any) => setDateOfBirth(date)}
                // name="passport_issue_date"
                // {...register('passport_issue_date')}
                />
              </FormControl>
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='place_of_birth'>Place of Birth</FormLabel>
                <Input id='place_of_birth' type='text' {...register('place_of_birth')} />
              </FormControl>

              <FormControl w={['100%', '34%']} isRequired>
                <FormLabel htmlFor='country_of_birth'>Country of Birth</FormLabel>
                <Select id='country_of_birth' placeholder='Select an option' {...register('country_of_birth')}>
                  {
                    countryList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl w={['100%', '34%']} isRequired>
                <FormLabel htmlFor='religion'>Religion</FormLabel>
                <Select id='religion' placeholder='Select an option' {...register('religion')}
                  onChange={(e) => {
                    const religion: any = e.target.value;
                    setReligion(religion);
                  }}
                >
                  {
                    religionList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
            </div>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='citizenship'>Citizenship</FormLabel>
                <Select id='citizenship' placeholder='Select an option'
                  {...register('citizenship')}
                  onChange={(e: any) => {
                    const citizenshipValue: any = e.target.value;
                    setCitizenship(citizenshipValue);
                  }}
                >
                  {
                    countryList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='sex'>Sex</FormLabel>
                <Select id='sex' placeholder='Select an option' {...register('sex')}>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                </Select>
              </FormControl>


            </div>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='civil_status'>Civil Status</FormLabel>
                <Select
                  id='civil_status'
                  placeholder='Select an option' {...register('civil_status')}
                  name='civil_status'
                  onChange={(e) => {
                    const civilStatusValue: any = e.target.value;
                    setCivilStatus(civilStatusValue);
                  }}
                >
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Separated">Separated</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widow">Widow</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>
              {civilStatus === "Other" && (
                <FormControl w={['100%', '50%']} isRequired>
                  <FormLabel htmlFor='other_civil_status'>Other Civil Status</FormLabel>
                  <Input id='other_civil_status' type='text' {...register('other_civil_status')} />
                </FormControl>
              )}
            </div>

            <div className="row">
              <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
                Application Details
              </Heading>
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='applied_country'>Applied Country</FormLabel>
                <Select
                  id='applied_country'
                  placeholder='Select an option' {...register('applied_country')}
                  name="applied_country"
                  onChange={(e) => {
                    const appliedCountry: any = e.target.value;
                    setCountry(appliedCountry);
                    getJobs(appliedCountry);
                  }}
                >

                  {
                    destinationList.map((item: any) => {
                      return (
                        <option value={item.id}>{item.name}</option>
                      )
                    })
                  }
                </Select>
              </FormControl>



              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='applied_position'>Applied Job</FormLabel>
                <Select id='applied_position' placeholder='Select an option' {...register('applied_position')}>
                  {
                    jobList.map((item) => {
                      return (
                        <option value={item.id}>{item.name}</option>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
            {appliedCountry === "3" && (
              <div className="row">    
                <div className="row">    
                  <FormControl w={['100%', '100%']} isRequired>
                    <FormLabel htmlFor='applicant_cv'>Upload Your CV</FormLabel>
                    <Input id='applicant_cv' className="form-control" type='file' {...register('applicant_cv')} />
                  </FormControl>
                  </div>
                  <div className="row">    
                  <FormControl w={['100%', '100%']} isRequired>
                    <FormLabel htmlFor='police_clearance_certificate'>Upload Your Police Clearance Certificate</FormLabel>
                    <Input id='police_clearance_certificate' className="form-control" type='file' {...register('police_clearance_certificate')} />
                  </FormControl>
                  </div>
                  
                  <FormControl w={['100%', '100%']} isRequired>
                    <FormLabel htmlFor='school_certificate'>Upload Your School Certificate</FormLabel>
                    <Input id='school_certificate' className="form-control" type='file' {...register('school_certificate')} />
                  </FormControl>
              </div>
            )}


            <div className="row">
              {/* <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='visa_specialist_name_by_applicant'>Your Visa Specialist Name</FormLabel>
                <Input id='visa_specialist_name_by_applicant' type='text' {...register('visa_specialist_name_by_applicant')} />
            </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='visa_specialist_phone_number_by_applicant'>Your Visa Specialist Number</FormLabel>
                <Input id='visa_specialist_phone_number_by_applicant' type='text' {...register('visa_specialist_phone_number_by_applicant')} />
              </FormControl> */}

              <FormControl w={['100%', '100%']} isRequired>
                <FormLabel htmlFor='assigned_agent_id'>Visa Specialist</FormLabel>
                <Select id='assigned_agent_id' placeholder='Select an option' {...register('assigned_agent_id')}>
                  {
                    employeeList.map((item) => {
                      return (
                        <option value={item.id}>{item.name} {item.sur_name}</option>
                      )
                    })
                  }
                </Select>
              </FormControl>

            </div>

            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Home Country Details
            </Heading>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_number'>Passport Number</FormLabel>
                <Input id='passport_number' type='text' {...register('passport_number')} />
              </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_issue_date'>Passport Date Of Issue</FormLabel>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='passport_issue_date'
                  className="form-control"
                  selected={passportIssueDate}
                  onChange={(date: any) => setPassportIssueDate(date)}
                // name="passport_issue_date"
                // {...register('passport_issue_date')}
                />
              </FormControl>

              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_expiry'>Passport Date Of Expiry</FormLabel>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='passport_expiry'
                  className="form-control"
                  selected={passportExpiry}
                  onChange={(date: any) => setPassportExpiry(date)}
                // onChange={(date) => setStartDate(date)}
                // name="passport_expiry"
                // {...register('passport_expiry')}
                />
              </FormControl>

              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_issued_by'>Issued By (Authoriy that issued the passport)</FormLabel>
                <Input id='passport_issued_by' type='text' {...register('passport_issued_by')} />

              </FormControl>
              <div className="row">
                <FormControl isRequired>
                  <FormLabel htmlFor='passport_image'>Upload Passport Copy</FormLabel>
                  <Input id='passport_image' className="form-control" type='file' {...register('passport_image')} />
                </FormControl>
              </div>
            </div>

            <div className="row">
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='home_country'>Home Country</FormLabel>
                <Select id='home_country' placeholder='Select an option' {...register('country')}>
                  {
                    countryList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='state'>State/Province</FormLabel>
                <Input id='state' type='text' {...register('state')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='city'>City</FormLabel>
                <Input id='city' type='text' {...register('city')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='postal_code'>Postal Code</FormLabel>
                <Input id='postal_code' type='text' {...register('postal_code')} />
              </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='address_line_1'>Address (Street And Number) Line 1</FormLabel>
                <Input id='address_line_1' type='text' {...register('address_line_1')} />
              </FormControl>
              <FormControl w={['100%', '50%']}>
                <FormLabel htmlFor='address_line_2'>Address (Street And Number) Line 2</FormLabel>
                <Input id='address_line_2' type='text' {...register('address_line_2')} />
              </FormControl>

            </div>
            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Current Residency and Work Details
            </Heading>
            <div className="row">
              <FormControl w="100%" isRequired>

                <FormLabel htmlFor='country_of_residence'>Country of Residence</FormLabel>
                <Select id='country_of_residence' placeholder='Select an option' {...register('country_of_residence')}
                  onChange={(e) => {
                    const residenceCountry = e.target.value;
                    setresidenceCountry(residenceCountry);
                  }}>
                  {
                    countryList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '50%']} >
                <FormLabel htmlFor='residence_mobile_number'>Current Residence Mobile Number(if have any)</FormLabel>
                <Input id='residence_mobile_number' type='text' {...register('residence_mobile_number')} />
              </FormControl>

              {residenceCountry !== "United Arab Emirates" && (
                <FormControl w={['100%', '50%']} isRequired>
                  <FormLabel htmlFor='residence_id_info'>Residence Id</FormLabel>
                  <Input id='residence_id_info' type='text' {...register('residence_id_info')} />
                </FormControl>
              )}
              {residenceCountry === "United Arab Emirates" && (

                <FormControl w={['100%', '50%']} isRequired>
                  <FormLabel htmlFor='residence_id_info'>Emirates Id In Valid Format (e.g 784-1999-222222-0)</FormLabel>
                  <Input id='residence_id_info' type='text' {...register('residence_id_info')} />
                </FormControl>
              )}
            </div>
            <div className="row">
              <FormControl w="100%" isRequired>
                <FormLabel htmlFor='visa_expiry'>Your ID/Visa Date of Validity</FormLabel>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='visa_expiry'
                  className="form-control"
                  selected={idVisaValidity}
                  onChange={(date: any) => setIdVisaValidity(date)}
                // {...register('visa_expiry')}
                // onChange={(date) => setStartDate(date)}
                />
              </FormControl>

              <FormControl w="100%" isRequired>
                <FormLabel htmlFor='residence_id_upload'>Upload Your Residence/Emirates ID</FormLabel>
                <Input id='residence_id_upload' className="form-control" type='file' {...register('residence_id_upload')} />
              </FormControl>
              <FormControl w="100%" >
                <FormLabel htmlFor='visa_copy'>Visa Copy</FormLabel>
                <Input id='visa_copy' type='file' className="form-control" {...register('visa_copy')} />
              </FormControl>
            </div>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='current_job_profession'>Profession As Per Current Job (or on Visa)</FormLabel>
                <Input id='current_job_profession' type='text' {...register('current_job_profession')} />
              </FormControl>
            </div>

            <div className="row">
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_state'>Work State/Province</FormLabel>
                <Input id='work_state' type='text' {...register('work_state')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_city'>Work City</FormLabel>
                <Input id='work_city' type='text' {...register('work_city')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_postal_code'>Work Place Postal Code</FormLabel>
                <Input id='work_postal_code' type='text' {...register('work_postal_code')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_place_address'>Work Place Street and Number</FormLabel>
                <Input id='work_place_address' type='text' {...register('work_place_address')} />
              </FormControl>

            </div>

            <div className="row">
              <FormControl w={['100%', '33%']}>
                <FormLabel htmlFor='company_name'>Name of Company</FormLabel>
                <Input id='company_name' type='text' {...register('company_name')} />
              </FormControl>
              <FormControl w={['100%', '33%']} >
                <FormLabel htmlFor='employer_phone_number'>Employer Phone Number</FormLabel>
                <Input id='employer_phone_number' type='text' {...register('employer_phone_number')} />
              </FormControl>
              <FormControl w={['100%', '33%']} >
                <FormLabel htmlFor='employer_email'>Email of the employer</FormLabel>
                <Input id='employer_email' type='text' {...register('employer_email')} />
              </FormControl>
            </div>
            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Payment Details
            </Heading>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='payment_receipt_image'>Payment Receipt Scanned Copy</FormLabel>
                <Input id='payment_receipt_image' type='file' className="form-control" {...register('payment_receipt_image')} />
              </FormControl>
            </div>

            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Contract Details
            </Heading>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='contract_scanned_image'>Contract Scanned Copy</FormLabel>
                <Input id='contract_scanned_image' type='file' className="form-control" {...register('contract_scanned_image')} />
              </FormControl>
            </div>


            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Payment Plan Details
            </Heading>
            <div className="row">
            <FormControl w={['100%', '100%']} isRequired>
                <FormLabel htmlFor='payment_plan_id'>Payment Plan</FormLabel>
                <Select id='payment_plan_id' placeholder='Select an option' {...register('payment_plan_id')}>
                  {
                    paymentPlanList.map((item) => {
                      return (
                        <option value={item.id}>{item.plan_name}</option>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>

            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Work Permit Details
            </Heading>
            <div className="row">
            <FormControl w={['100%', '100%']} isRequired>
                <FormLabel htmlFor='work_permit_priority'>Work Permit Priority</FormLabel>
                <Select id='work_permit_priority' placeholder='Select an option' {...register('work_permit_priority')}>
                  {
                    workPermitPriorityList.map((item) => {
                      return (
                        <option value={item}>{item}</option>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
            
            {/* <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Application Status and Work Permit Details
            </Heading>
            <div className="row">
            <FormControl w={['100%', '34%']} isRequired>
                <FormLabel htmlFor='status'>Application Status</FormLabel>
                <Select id='status' placeholder='Select an option' {...register('status')}>
                  { 
                    applicationStatus.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '50%']}>
                    <FormLabel htmlFor='job_offer_scanned_copy'>Job Offer Letter Copy</FormLabel>
                    <Input id='job_offer_scanned_copy' type='file' className="form-control" {...register('job_offer_scanned_copy')} />
                  </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '50%']}>
                    <FormLabel htmlFor='work_permit_scanned_copy'>Work Permit Copy</FormLabel>
                    <Input id='work_permit_scanned_copy' type='file' className="form-control" {...register('work_permit_scanned_copy')} />
                  </FormControl>
            </div> */}
            {/* <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Coupon Details
            </Heading>
            <div className="row">
              <FormControl w="100%">
                    <FormLabel htmlFor='coupon_code'>Coupon Code (if you have any)</FormLabel>
                    <Input id='coupon_code' type='text' {...register('coupon_code')} />
                  </FormControl>
            </div> */}
            <Button type="submit"
              isLoading={isLoadingSubmitButton}
              colorScheme="blue" mt={10} mb={20} size="lg">
              Submit
            </Button>
          </form>
          <div>
            <ToastContainer
              position="bottom-right"
              hideProgressBar={true}
              theme='colored'

            />
          </div>
        </Box>
      </ChakraProvider>
    </div>
  );
};
