import React from 'react'
import { getJSDocReadonlyTag } from 'typescript'
import { Logo } from "./Logo"

export const Header = () => {
    let   navitem = {
        width: '100%',
        color: 'white',
        'font-size':'12px',
        margin:'10px 20px 10px 10px',
        padding:'0px 0px 0px 0px',
        }
      
        let bggrey ={
      color:'white'  
        }


   
    return (
          <>
          {/* <div>   */}
          {/* <nav className="navbar navbar-expand-lg bg-dark" > */}



    {/* </nav>   */}
    
      {/* </div> */}
      <div>
           <nav className="navbar navbar-expand-lg navbar-light bg-grey">
  <div className="container-fluid">

      <div><span><a href="https://wisgroups.com/" rel='noreferrer' target='_blank'>
        <img className="imagia attachment-full size-full" alt='' width="70" height="40" src="https://wg-media-cdn.s3.ap-south-1.amazonaws.com/CompanyPublicImages/wizard_logo.png"/>
      </a>
       </span></div>
 
  </div>
</nav>
      </div>

      </>
     
    )
}
