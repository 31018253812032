import axios from "axios";
export default axios.create({
  // baseURL: "http://localhost:8000/api",
  // baseURL: "https://795b-5-32-34-22.ngrok.io/api/",
  // baseURL: "http://wgbackofficestagging.eba-ezu4se52.ap-south-1.elasticbeanstalk.com/api",
  baseURL: "https://admin-stage.wisgroups.com/api/",
  // baseURL: "https://admin.wisgroups.com/api/",

  headers: {
    "Content-type": "application/json"
  }
});